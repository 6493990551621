import axios from 'axios';
import openNotification from '../utils/openNotification';

const $host = axios.create({
  
});

const $host2 = axios.create({
  
});

$host.interceptors.response.use(
  (config) => config,
  async error => { 
    if (error?.response?.status === 406) {     
      openNotification('error', error?.response?.data?.error?.message);
    }

    throw error;
  }
);

export { $host,$host2 };
