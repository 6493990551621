import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getCategoriesApi } from '../../http/main';

const Categories = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const categories = useAppSelector(state => state.mainReducer.categories);

  useEffect(() => {
    dispatch(getCategoriesApi());
  }, []);

  return (
    <div className='flex max-lg:flex-col justify-center md:my-14 mb-14 px-5'>
      <div className='lg:w-[1180px]'>
        <div className='flex justify-between'>
          <h1 className='sm:text-4xl text-2xl font-semibold'>Категории</h1>
        </div>
        <div className='mt-10 overflow-x-auto' style={{ maxHeight: '60vh', overflowY: 'auto' }}>
          <div className='flex justify-between gap-8'>
            {categories.map((category: any) => (
              <div
                className='flex justify-center cursor-pointer'
                onClick={() => navigate(`/restaurantByCategory/${category.diet_type_id}`)}
              >
                <div>
                  <div
                    className='w-[64px] h-[64px] md:w-[90px] md:h-[90px] rounded-full flex items-center justify-center'
                    style={{ backgroundColor: '#F6F6F6' }}
                  >
                    <img src={category?.photo} alt='' />
                  </div>
                  <p className='text-center mt-2'>{category?.title?.RU}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
