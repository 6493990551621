const kfcList = [
    {
        name: 'karabaev',
        zoodId: 'RES1714998349QTH26661',
        kfcId: 'c542d8f6-f30a-4328-b101-2499b319a455'
    },
    {
        name: 'Profsoyuz',
        zoodId: 'RES1714997255PKT82861',
        kfcId: '5bc0239f-de12-4176-8214-2d3c9268bf7c'
    },
    {
        name: '8mart',
        zoodId: 'RES1714548269AKV18940',
        kfcId: 'fd36dc02-b7fe-44cc-a427-30cf8ba4aac5'
    },
    {
        name: '82mkr',
        zoodId: 'RES1673862960IAX61013',
        kfcId: 'd0a295d2-3302-49ee-bc29-4020cc514121'
    },
    {
        name: 'Siyoma',
        zoodId: 'RES1714547593GNH104428',
        kfcId: '1448fca3-b7a2-437d-90ff-64fcd0ff98d1'
    },
    {
        name: 'Operka',
        zoodId: 'RES1714546075XMF101969',
        kfcId: 'e91f8e37-e700-4bcd-90e8-a4dffc35800f'
    },
    {
        name: 'Auchan',
        zoodId: 'RES1713351749VYC96389',
        kfcId: 'c984d8da-031d-452d-9e92-b1c7d9b3c877'
    },
]

export default kfcList;