import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface AppState {
  restaurants: any,
  restaurantinfo: any,
  restaurantByCategory:any
  restaurantItems: [],
  orders: any,
  orderPrice: number,
  isBranchModalOpen: boolean,
  restaurantBranches: any,
  restaurantMenu: any,
  coordinates: any
  restaurantId: any,
  kfcOrder: any,
  isKfc: boolean
  pickedKfcRestaurantId: any,
  kfcMenuList: any,
  stopList: any
}

const initialState: AppState = {
  restaurants: [],
  restaurantinfo: '',
  restaurantByCategory: [],
  restaurantItems: [],
  orders: [],
  orderPrice: 0,
  isBranchModalOpen: false,
  restaurantBranches: [],
  restaurantMenu: {},
  coordinates: [],
  restaurantId: '',
  kfcOrder: [],
  isKfc: false,
  pickedKfcRestaurantId: '',
  kfcMenuList: [],
  stopList: []
};

const restaurants = createSlice({
  name: 'restaurants',
  initialState,
  reducers: {
    stopListAC(state, action: PayloadAction<any>) { 
      state.stopList = action.payload
    },
    kfcMenuListAC(state, action: PayloadAction<any>) { 
      state.kfcMenuList = action.payload
    },
    pickedKfcRestaurantIdAC(state, action: PayloadAction<any>) { 
      state.pickedKfcRestaurantId = action.payload
    },
    isKfcAC(state, action: PayloadAction<any>) { 
      state.isKfc = action.payload
    },
    restaurantMenuAC(state, action: PayloadAction<any>) { 
      state.restaurantMenu = action.payload      

      if (state.stopList.length > 0) {
        for (let i = 0; i < state.restaurantMenu.length; i++) {
          if (state.restaurantMenu[i].itemList.length > 0) {
            for (let k = 0; k < state.restaurantMenu[i].itemList.length; k++) {
              for (let j = 0; j < state.stopList.length; j++) {
                if (state.stopList[j].itemName === state.restaurantMenu[i].itemList[k].title) {
                  state.restaurantMenu[i].itemList.splice(k, 1)                  
                }
              }
            }
          }
        }
      }
    },
    restaurantBranchesAC(state, action: PayloadAction<any>) { 
      state.restaurantBranches = action.payload
    },
    isBranchModalOpenAC(state, action: PayloadAction<any>) { 
      state.isBranchModalOpen = action.payload
    },
    clearOrderAC(state, action: PayloadAction<any>) { 
      state.orders = action.payload
      state.orderPrice = 0
    },
    ordersAC(state, action: PayloadAction<any>) { 
      state.orders = action.payload
    },
    increaseOrderPriceAC(state, action: PayloadAction<any>) { 
      state.orderPrice += action.payload
    },
    decreaseOrderPriceAC(state, action: PayloadAction<any>) { 
      state.orderPrice -= action.payload
    },
    orderPriceAC(state, action: PayloadAction<any>) { 
      state.orderPrice = 0;
      action.payload.forEach((item:any) => {
        state.orderPrice += item.total_price
      })
    },
    restaurantItemsAC(state, action: PayloadAction<any>) { 
      state.restaurantItems = action.payload
    },
    restaurantByCategoryAC(state, action: PayloadAction<any>) { 
      state.restaurantByCategory = action.payload
    },
    restaurantinfoAC(state, action: PayloadAction<any>) { 
      state.restaurantinfo = action.payload
    },
    restaurantsAC(state, action: PayloadAction<any>) {     
      state.restaurants = [];
      for (let i = 0; i < action.payload.length; i++) {       
        for (let k = 0; k < action.payload[i].restaurants.length; k++) {
          state.restaurants.push({
            restaurant: action.payload[i].restaurants[k],
            category_name: action.payload[i].category_nameRU
          })
        }
      }
    },
    coordinatesAC(state, action: PayloadAction<any>) { 
      state.coordinates = action.payload
    },
    restaurantIdAC(state, action: PayloadAction<any>) { 
      state.restaurantId = action.payload
    },
    kfcOrderAC(state, action: PayloadAction<any>) { 
      state.kfcOrder = action.payload
    },
    addToKfcOrderAC(state, action: PayloadAction<any>) { 
      let existingOrder = state.kfcOrder.find((o:any) => o.id === action.payload.id);

      if (existingOrder) {
          existingOrder.quantity += 1;
      } else {
        state.kfcOrder.push(action.payload);
      }
    },
    removeFromKfcOrderAC(state, action: PayloadAction<any>) { 
      let orderIndex = state.kfcOrder.findIndex((o:any) => o.id === action.payload);
    
      // Если объект найден
      if (orderIndex !== -1) {
          // Уменьшаем quantity на единицу
          state.kfcOrder[orderIndex].quantity -= 1;
          
          // Если quantity равен 0, удаляем объект из массива
          if (state.kfcOrder[orderIndex].quantity === 0) {
            state.kfcOrder.splice(orderIndex, 1);
          }
      }
    },
  },
});

export const {
  stopListAC,
  removeFromKfcOrderAC,
  addToKfcOrderAC,
  kfcMenuListAC,
  pickedKfcRestaurantIdAC,
  isKfcAC,
  kfcOrderAC,
  restaurantIdAC,
  orderPriceAC, 
  restaurantsAC, 
  restaurantinfoAC,
  restaurantByCategoryAC, 
  restaurantItemsAC, 
  ordersAC, 
  increaseOrderPriceAC,
  decreaseOrderPriceAC, 
  clearOrderAC,
  isBranchModalOpenAC,
  restaurantBranchesAC,
  restaurantMenuAC,
  coordinatesAC
} = restaurants.actions;
export default restaurants.reducer;
