import { $host }from './index';
import { AppDispatch } from '../store';
import { kfcMenuListAC, orderPriceAC, ordersAC, restaurantByCategoryAC, restaurantinfoAC, restaurantItemsAC, restaurantMenuAC, restaurantsAC, stopListAC } from '../store/reducers/restaurants';
import { isLoadingAC } from '../store/reducers/app';
import { fareAC, orderInfoAC } from '../store/reducers/main';
import openNotification from '../utils/openNotification';

const getRestaurantListApi = () => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host.get(`https://api.food.zood.tj:2222/get-all-restaurants`);      
      dispatch(restaurantsAC(data))
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const getRestaurantByIdApi = (id:any, callback?:any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host.get(`https://api.food.zood.tj:2222/get-restaurant-by-id/${id}`);
      if (data) {
        callback(data)
      }
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const getRestaurantItemsApi = (id:any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host.get(`https://api.food.zood.tj:2222/get-restaurant-items/${id}`);
      dispatch(restaurantItemsAC(data))
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const getRestaurantByDietTypeIdApi = (id:any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host.get(`https://api.food.zood.tj:2222/getRestaurantByDietTypeId/${id}`);
      dispatch(restaurantByCategoryAC(data))
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const getRestaurantMenuApi = (id:any, callback: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host.get(`https://api.food.zood.tj/api/restaurant/restaurant?restaurant_id=${id}&longitude=0.0&latitude=0.0&code=RU`);
      if (data.payload.itemCategoryDetails.length > 0) {
        dispatch(restaurantMenuAC(data.payload.itemCategoryDetails))
        dispatch(restaurantinfoAC(data.payload))
      } else {
        callback()
      }
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const postOrderApi = (params:any, callback:any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host.post(`https://api.food.zood.tj/api/order/place-order`, params);

      if (data) {
        callback(data.payload)
      }
      
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const getCartItemsApi = (id:any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host.get(`https://api.food.zood.tj/api/cart/list-cart-items/${id}/consumer/RU`);      
      dispatch(ordersAC(data.payload.cartItems))
      dispatch(orderPriceAC(data.payload.cartItems))
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const addToCartApi = (params: any, consumer_id:any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true));
      await $host.post('https://api.food.zood.tj/api/cart/add-to-cart/consumer', params, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );
    dispatch(getCartItemsApi(consumer_id))
    
    } finally {
      dispatch(isLoadingAC(false));
    }
  };

  const updateCartApi = (params: any, consumer_id:any, cart_id: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true));
      await $host.post(`https://api.food.zood.tj/api/cart/update-cart/${cart_id}`, params, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );
    dispatch(getCartItemsApi(consumer_id))
    
    } finally {
      dispatch(isLoadingAC(false));
    }
  };

  const removeFromCartApi = (consumer_id:any, cart_id: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true));
      await $host.delete(`https://api.food.zood.tj/api/cart/remove-from-cart/consumer/${consumer_id}/${cart_id}`);
      dispatch(getCartItemsApi(consumer_id))
    
    } finally {
      dispatch(isLoadingAC(false));
    }
  };

  const deliveryChargeApi = (params: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true));
      const { data } = await $host.post(`https://api.food.zood.tj/api/order/deliveryBoy_charge`, params, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );      
    dispatch(fareAC(data.payload.fare))
    } finally {
      dispatch(isLoadingAC(false));
    }
  };

  const cancelOrderApi = (params: any, callback: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true));
      const { data } = await $host.post(`https://api.food.zood.tj/api/consumer/cancel-order-counsumer`, params, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );      
    if (data.payload.status === 'cancelled') {
      callback()
    }
    } finally {
      dispatch(isLoadingAC(false));
    }
  };

  const getOrderDetailsApi = (id:any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host.get(`https://api.food.zood.tj/api/order/get-order-details?order_id=${id}&code=RU`);      
      dispatch(orderInfoAC(data.payload))
      
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const getKfcRestaurantsApi = () => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host.get(`https://api.food.zood.tj:2000/get-kfc-restaurants`);      
      
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const getKfcMenuApi = () => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host.get(`https://api.food.zood.tj:2000/get-kfc-restaurant-menu`);           
      dispatch(kfcMenuListAC(data))
      
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const getKfcStopListApi = (params:any, callback: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      const { data } = await $host.get(`https://api.food.zood.tj:2000/get-stoplist/${params}`);           
      

      if (data) {
        callback()
        dispatch(stopListAC(data))
      }
      
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const kfcAuthApi = () => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true))
      await $host.get(`https://api.food.zood.tj:2000/kfc-authorization`);      
      
    } finally {
      dispatch(isLoadingAC(false))
    }
  };

  const orderFromKfcApi = (params: any, orderId:any , callback:any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoadingAC(true));
      const { data } = await $host.post(`https://api.food.zood.tj:2000/post-new-kfc-order`, params);      
      if (data) {
        callback(data)
      }
      
    } catch (e) {
      const formData: any = new URLSearchParams();

      formData.append('code', 'RU');
      formData.append('order_id', orderId);

      dispatch(
        cancelOrderApi(formData, () => {
          openNotification('error', 'Сервис временно недоступен повторите ошибку позже');
        })
      );
      
    } finally {
      dispatch(isLoadingAC(false));
    }
  };

  export {
    getRestaurantListApi,
    getRestaurantByIdApi,
    getRestaurantItemsApi,
    getRestaurantByDietTypeIdApi,
    getRestaurantMenuApi,
    postOrderApi,
    getCartItemsApi,
    addToCartApi,
    updateCartApi,
    removeFromCartApi,
    deliveryChargeApi,
    cancelOrderApi,
    getOrderDetailsApi,
    getKfcMenuApi,
    kfcAuthApi,
    orderFromKfcApi,
    getKfcRestaurantsApi,
    getKfcStopListApi
  };